import * as THREE from 'three'
import { createContext, useMemo, useRef, useState, useContext, useLayoutEffect, forwardRef, useEffect } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { Html, QuadraticBezierLine, useIntersect } from '@react-three/drei'
import { useDrag } from '@use-gesture/react'
import { mergeRefs } from 'react-merge-refs'

const context = createContext()
const Circle = forwardRef(({
                             children,
                             opacity = 1,
                             radius = 0.05,
                             segments = 32,
                             color = '#ff1050',
                             ...props
                           }, ref) => (
  <mesh ref={ref} {...props}>
    <circleGeometry args={[radius, segments]} />
    <meshBasicMaterial transparent={opacity < 1} opacity={opacity} color={color} />
    {children}
  </mesh>
))

export function Nodes({ children }) {
  const group = useRef()
  const [nodes, set] = useState([])
  const lines = useMemo(() => {
    const lines = []
    for (let node of nodes)
      node.connectedTo
        .map((ref) => [node.position, ref.current.position])
        .forEach(([start, end]) => lines.push({
          start: start.clone().add({ x: 0.35, y: 0, z: 0 }),
          end: end.clone().add({ x: -0.35, y: 0, z: 0 }),
        }))
    return lines
  }, [nodes])
  useFrame((_, delta) => group.current.children.forEach((group) => (group.children[0].material.uniforms.dashOffset.value -= delta * 10)))
  const [hovered, setHovered] = useState(-1)

  return (
    <context.Provider value={set}>
      <group ref={group}>
        {lines.map((line, index) => (
          <group
            key={index}
            onPointerEnter={() => setHovered(index)}
            onPointerLeave={() => setHovered(-1)}
          >
            <QuadraticBezierLine
              {...line}
              color={hovered === index ? '#ff1050' : 'green'}
              lineWidth={hovered === index ? 4 : 2}
              transparent
              opacity={0.5}
            />
          </group>
        ))}
      </group>
      {children}
      {lines.map(({ start, end }, index) => (
        <group key={index} position-z={1}>
          <Circle position={start} />
          <Circle position={end} />
        </group>
      ))}
    </context.Provider>
  )
}

export const Node = forwardRef(({ color = 'black', name, connectedTo = [], position = [0, 0, 0], ...props }, ref) => {
  const set = useContext(context)
  const { size, camera } = useThree()
  const [pos, setPos] = useState(() => new THREE.Vector3(...position))
  const state = useMemo(() => ({ position: pos, connectedTo }), [pos, connectedTo])
  // Register this node on mount, unregister on unmount
  useLayoutEffect(() => {
    set((nodes) => [...nodes, state])
    return () => void set((nodes) => nodes.filter((n) => n !== state))
  }, [state, pos])
  // Drag n drop, hover
  const [hovered, setHovered] = useState(false)
  useEffect(() => void (document.body.style.cursor = hovered ? 'grab' : 'auto'), [hovered])
  const bind = useDrag(({ down, xy: [x, y] }) => {
    document.body.style.cursor = down ? 'grabbing' : 'grab'
    setPos(new THREE.Vector3((x / size.width) * 2 - 1, -(y / size.height) * 2 + 1, 0).unproject(camera).multiply({
      x: 1,
      y: 1,
      z: 0,
    }).clone())
  })

  const [hidden, setHidden] = useState(false)
  const insecRef = useIntersect((visable) => {
    console.log(visable)
    setHidden(!visable)
  })

  return (
    <Circle ref={mergeRefs([ref, insecRef])} {...bind()} opacity={0.2} radius={0.5} color={color}
            position={pos} {...props}>
      <Circle
        radius={0.25}
        position={[0, 0, 0.1]}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
        color={hovered ? '#ff1050' : color}>
        <Html position={[0, 0, 1]} center style={{ pointerEvents: 'none' }}>
          {!hidden && <span style={{ color: '#FFF' }}>{name}</span>}
        </Html>
      </Circle>
    </Circle>
  )
})
